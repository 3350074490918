import { Icon } from '@iconify/react'
import {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useGetSessions from 'src/apis/useGetSessions'
import { CAI_CHAT_FLOWS } from 'src/constants/cai-chat.constant'
import { ItemStatus } from 'src/types/session.type'
import styled from 'styled-components'
import { CaiPopupContext } from '../CaiPopup/Context'
import { NotificationContext } from './NotificationContext'

type Props = {
  newChat: (selectedFlow: string) => void
  setMessagesMap: Dispatch<SetStateAction<any>>
}

const NotificationItem = styled('li')({
  '&:hover': { cursor: 'pointer', backgroundColor: '#ddd' },
  '&:not(:last-child)': { borderBottom: '1px solid #bbb !important' }
})

function Notification({ newChat, setMessagesMap }: Props) {
  const { t } = useTranslation()
  const { sessionId, setSessionId, handleCompleteActivity } =
    useContext(NotificationContext)

  const [show, setShow] = useState(false)
  const { notificationRef } = useContext(CaiPopupContext)
  const { data = [] } = useGetSessions()

  const completedData = data.filter((m) => m.status === ItemStatus.completed)

  const incompleteData = data.filter((m) => m.status !== ItemStatus.completed)

  const hasUnread = data.some((m) => m.status === ItemStatus.ready)

  const handleShowToggle = () => setShow((state) => !state)

  const handleItemClick = (id: string) => {
    handleShowToggle()
    if (id === sessionId) return
    newChat(CAI_CHAT_FLOWS.SESSION)
    setSessionId(id)
    setMessagesMap((state: any) => ({ ...state, session: [] }))
  }

  useEffect(() => {
    if (sessionId) newChat(CAI_CHAT_FLOWS.SESSION)
  }, [])

  if (handleCompleteActivity) return null

  return (
    <OverlayTrigger
      placement="bottom"
      show={show}
      onToggle={handleShowToggle}
      overlay={
        <Popover style={{ maxWidth: 300, width: 300 }}>
          <Popover.Header as="h3">
            {t('notification.title', 'Notification')}
          </Popover.Header>

          <Popover.Body className="p-0">
            <ul
              className="list-group rounded-bottom rounded-top-0 overflow-auto"
              style={{ maxHeight: '50vh' }}
            >
              {[...incompleteData].reverse().map((m) => (
                <NotificationItem
                  key={m.id}
                  className="list-group-item border-0 d-flex flex-column rounded-0"
                  onClick={() => handleItemClick(m.id)}
                >
                  <div className="fw-bold fs-6">
                    {m.title}
                    <Icon
                      icon="material-symbols-light:circle"
                      width="10"
                      height="10"
                      className="ms-1"
                    />
                  </div>

                  <div>{m.description}</div>

                  <div className="align-self-end text-muted">
                    {new Date(m.updated_at).toLocaleString()}
                  </div>
                </NotificationItem>
              ))}

              {!!completedData.length && (
                <div
                  className="fw-bold px-3"
                  style={{ borderBottom: '1px solid #bbb' }}
                >
                  {t('notification.completed', 'Completed')}
                </div>
              )}

              {[...completedData].reverse().map((m, i) => (
                <NotificationItem
                  key={m.id}
                  className="list-group-item border-0 d-flex flex-column rounded-0"
                  onClick={() => handleItemClick(m.id)}
                  style={{ background: 'white' }}
                >
                  <div className="fw-bold fs-6">{m.title}</div>

                  <div>{m.description}</div>

                  <div className="align-self-end text-muted">
                    {new Date(m.updated_at).toLocaleString()}
                  </div>
                </NotificationItem>
              ))}
            </ul>
          </Popover.Body>
        </Popover>
      }
      trigger={'click'}
      rootClose
    >
      <Icon
        ref={notificationRef as any}
        icon={`material-symbols-light:notifications${
          hasUnread ? '-unread' : ''
        }`}
        role="button"
        width={24}
        height={24}
        onClick={() => document.body.click()}
        style={{ color: hasUnread ? 'rgb(220, 0, 0)' : 'inherit' }} // Change color to red if unread
        className={hasUnread ? 'pulse-alert glow' : ''}
      />
    </OverlayTrigger>
  )
}

export default memo(Notification)
