enum ItemSource {
  internal = 'internal',
  external = 'external'
}

export enum ItemStatus {
  ready = 'READY',
  inProgress = 'IN_PROGRESS',
  completed = 'COMPLETED'
}

export type Item = {
  created_at: string
  description: string
  id: string
  source: ItemSource
  status: ItemStatus
  title: string
  type: string
  updated_at: string
  report: string
}
