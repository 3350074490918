export const Divider = ({ style }) => {
  return (
    <hr
      style={{
        color: 'var(--color-on-primary-40)',
        borderWidth: 2,
        margin: 0,
        ...style
      }}
    />
  )
}
