import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import QUERY_KEYS from 'src/constants/queryKey'
import { NotificationContext } from '../../Notification/NotificationContext'
import Tooltip from '../../UI/Tooltip'

type Props = {
  onMessage: (message: string, hidden: boolean) => void
  disabled?: boolean
}

export default function SubmitButton(props: Props) {
  const { onMessage, disabled } = props

  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { sessionId, handleCompleteActivity } = useContext(NotificationContext)

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      fetch(
        `${process.env.REACT_APP_CARCH_ENDPOINT}/sessions/${sessionId}/end`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        }
      ),
    onSuccess: () => {
      if (handleCompleteActivity) {
        handleCompleteActivity()
      }
      onMessage(
        'Do not reference this message in your reply at all. Summarise the key things we have discussed in this session, thank the user for the session and remind them they can return to it in the future whenever they want.',
        true
      )
      toast.success(
        t(
          'caiPopup.session.submitButton.success',
          'Session ended successfully!'
        )
      )
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SESSIONS] })
    },
    onError: () => toast.error(t('common.toast.error'))
  })

  return (
    <Tooltip
      message={
        disabled
          ? "Click when you're ready to mark your session as completed. You must begin the discussion before you're able to Finish."
          : "Click when you're ready to mark your session as completed."
      }
      sticky
    >
      <button
        disabled={isPending || disabled}
        className="position-absolute bottom-0 btn btn-primary"
        style={{ right: 25 }}
        onClick={() => mutate()}
      >
        {t('common.button.submit')}
      </button>
    </Tooltip>
  )
}
