import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { StrictMode, Suspense, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import './assets/scss/main.scss'
import { AuthProvider } from './components/Auth/AuthProvider'
import MaintenancePage from './components/Pages/MaintenancePage'
import i18n from './i18n'

const httpLink = createHttpLink({ uri: process.env.REACT_APP_SERVER_ENDPOINT })

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken')
  return {
    headers: { ...headers, authorization: token ? `Bearer ${token}` : '' }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const container = document.getElementById('root')
const root = createRoot(container)

const MaintenanceWrapper = ({ children }) => {
  const [isAllowedIP, setIsAllowedIP] = useState(false)

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        const userIp = data.ip
        const allowedIPs = process.env.REACT_APP_MAINTENANCE_IPS?.split(',')

        setIsAllowedIP(allowedIPs?.includes(userIp))
      } catch (error) {
        console.error('Failed to fetch IP address', error)
      }
    }

    fetchIPAddress()
  }, [])

  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true'

  if (isMaintenanceMode && !isAllowedIP) {
    return <MaintenancePage />
  }

  return children
}

root.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <AuthProvider>
              <MaintenanceWrapper>
                <Suspense>
                  <App />
                </Suspense>
                <ToastContainer />
              </MaintenanceWrapper>
            </AuthProvider>
          </I18nextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ApolloProvider>
  </StrictMode>
)
