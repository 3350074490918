import { memo, useContext, useEffect, useState } from 'react'
import { Spinner, Stack } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSpeechRecognition } from 'react-speech-recognition'
import i18n from 'src/i18n'
import { CAI_CHAT_FLOWS } from '../../constants/cai-chat.constant'
import { useGetEmployeesQuery, useGetTeamsQuery } from '../../gql/graphql'
import { getSummaryText, submitFeedback } from '../../utils/platform'
import PanelTitle from '../Agent/PanelTitle'
import { AuthContext } from '../Auth/AuthProvider'
import STTButton from '../shared/STTButton'
import CAIIconThinking from '../UI/CAIIconThinking'
import Tooltip from '../UI/Tooltip'
import FeedbackButtons from './FeedbackButtons'

const Feedback = (props) => {
  const {
    flow,
    setFlow,
    awaitingResponse,
    initialFeedback,
    initialFeedbackLoading
  } = props

  const { t } = useTranslation()
  const speechApi = useSpeechRecognition()

  const { userInfo, auth } = useContext(AuthContext)
  const organizationId = userInfo?.organization?.id
  const skip = !userInfo || flow !== CAI_CHAT_FLOWS.FEEDBACK

  const [selectedOption, setSelectedOption] = useState('person')
  const [selectedPerson, setSelectedPerson] = useState('')
  const [selectedTeam, setSelectedTeam] = useState('')
  const [currentFeedback, setCurrentFeedback] = useState(initialFeedback)
  const [loading, setLoading] = useState(initialFeedbackLoading)

  const { data: employees } = useGetEmployeesQuery({
    variables: { roleId: userInfo?.employeeDetails.role.id, organizationId },
    skip
  })

  const { data: teams } = useGetTeamsQuery({
    variables: { organizationId },
    skip
  })

  const handleFeedbackButtonsClick = async (id) => {
    if (id !== 'anonymous' && id !== 'direct') return

    const targetId = selectedOption === 'person' ? selectedPerson : selectedTeam
    const senderId = userInfo?.stsUserId
    const targetType = selectedOption === 'person' ? 'user' : 'team'
    const feedback = currentFeedback

    if (id === 'anonymous') {
      await submitFeedback(
        auth?.token,
        senderId,
        targetId,
        targetType,
        feedback,
        true,
        i18n.language
      )
    } else if (id === 'direct') {
      await submitFeedback(
        auth?.token,
        senderId,
        targetId,
        targetType,
        feedback,
        false,
        i18n.language
      )
    }
  }

  const handleReformulateClick = () => {
    setLoading(true)
    getSummaryText(auth?.token, currentFeedback).then((summary) => {
      setCurrentFeedback(summary)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!initialFeedback) return
    setCurrentFeedback(initialFeedback)
  }, [initialFeedback])

  useEffect(() => {
    setLoading(initialFeedbackLoading)
  }, [initialFeedbackLoading])

  return (
    <div
      className="d-flex flex-column card card-chat-body border-0 order-1 w-100 position-relative h-100 p-3 gap-2"
      style={{ backgroundColor: '#ffe6e6' }}
    >
      <div className="h-100 position-relative d-flex overflow-hidden">
        <div className="h-100 w-100 position-absolute h-100">
          <div className="flex-grow-1 mh-100 overflow-auto gap-3 d-flex flex-column h-100">
            <div className="d-flex justify-content-between align-items-end gap-2">
              <div className="d-flex align-items-center gap-2">
                <PanelTitle
                  name={t(
                    'caiPopup.feedback.panelTitle',
                    'Who is this FeedForward for?'
                  )}
                />

                <div className="d-flex gap-2 align-items-center ms-3">
                  <input
                    type="radio"
                    id="selectPerson"
                    name="feedbackTarget"
                    value="person"
                    checked={selectedOption === 'person'}
                    onChange={() => setSelectedOption('person')}
                  />

                  <label htmlFor="selectPerson" className="mb-0">
                    <strong>{t('caiPopup.feedback.person', 'Person')}</strong>
                  </label>
                </div>

                <br />

                <div className="d-flex gap-2 align-items-center">
                  <input
                    type="radio"
                    id="selectTeam"
                    name="feedbackTarget"
                    value="team"
                    checked={selectedOption === 'team'}
                    onChange={() => setSelectedOption('team')}
                  />

                  <label htmlFor="selectTeam" className="mb-0">
                    <strong>{t('caiPopup.feedback.team', 'Team')}</strong>
                  </label>
                </div>
              </div>

              <div className="d-flex justify-content-end gap-2">
                <Tooltip
                  message={t(
                    'caiPopup.feedback.tooltip.saveForLater',
                    'Save for later.'
                  )}
                >
                  <button className="btn btn-light text-dark">
                    <i className="icofont-save"></i>
                  </button>
                </Tooltip>

                <Tooltip
                  message={t(
                    'caiPopup.feedback.tooltip.returnToChatMode',
                    'Return to chat mode.'
                  )}
                >
                  <button
                    className="btn btn-danger text-light"
                    onClick={() => setFlow(CAI_CHAT_FLOWS.DEFAULT)}
                  >
                    <i className="icofont-close"></i>
                  </button>
                </Tooltip>
              </div>
            </div>

            <div className="d-flex flex-column gap-4 align-items-start">
              <div className="w-100">
                {selectedOption === 'person' && (
                  <select
                    className="form-select"
                    style={{ backgroundColor: 'white' }}
                    value={selectedPerson}
                    onChange={(e) => setSelectedPerson(e.target.value)}
                  >
                    <option value="">
                      {t('caiPopup.feedback.selectPerson', 'Select a person')}
                    </option>

                    {employees?.employees.map((e) => {
                      const { firstName, middleName, lastName } =
                        e.employeeDetails
                      return (
                        <option key={e.id} value={e.stsUserId}>
                          {`${firstName ?? ''} ${middleName ?? ''} ${
                            lastName ?? ''
                          }`}
                        </option>
                      )
                    })}
                  </select>
                )}

                {selectedOption === 'team' && (
                  <select
                    className="form-select"
                    style={{ backgroundColor: 'white' }}
                    value={selectedTeam}
                    onChange={(event) => setSelectedTeam(event.target.value)}
                  >
                    <option value="">
                      {t('caiPopup.feedback.selectTeam', 'Select a team')}
                    </option>
                    {teams?.teams.map((t) => (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <PanelTitle
                name={t(
                  'caiPopup.feedback.summaryPanelTitle',
                  'FeedForward Summary'
                )}
              />

              <div className="d-flex gap-2 align-items-center">
                <div
                  className="rounded-circle"
                  style={{ background: 'var(--primary-color)' }}
                >
                  <STTButton
                    awaitingResponse={loading || awaitingResponse}
                    setText={setCurrentFeedback}
                    {...speechApi}
                  />
                </div>

                <Tooltip
                  message={
                    !currentFeedback
                      ? 'Please insert a FeedForward before reformulating.'
                      : 'Have Cai reformat your FeedForward for you'
                  }
                >
                  <span>
                    <button
                      className="btn btn-primary"
                      style={{ alignSelf: 'flex-end' }}
                      onClick={handleReformulateClick}
                      disabled={!currentFeedback || loading}
                    >
                      Cai Format
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>

            {loading ? (
              <Stack style={{ height: '100vh' }}>
                <Spinner variant="primary" style={{ margin: 'auto' }} />
              </Stack>
            ) : (
              <div
                className="flex-grow-1 rounded-3 overflow-auto"
                style={{ backgroundColor: '#ffffff' }}
              >
                <textarea
                  value={currentFeedback}
                  placeholder={t(
                    'caiPopup.feedback.placeholder',
                    'Type your FeedForward here...'
                  )}
                  className="w-100 h-100 form-control"
                  style={{ resize: 'none' }}
                  onChange={(e) => setCurrentFeedback(e.target.value)}
                />

                {loading && <CAIIconThinking awaitingResponse={loading} />}
              </div>
            )}

            <FeedbackButtons
              setFlow={setFlow}
              handleFeedbackButtonsClick={handleFeedbackButtonsClick}
              disabled={
                (!selectedPerson && !selectedTeam) || !currentFeedback.length
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Feedback)
