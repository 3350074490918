import { merge } from 'lodash'
import { createContext, useEffect, useState } from 'react'
import { Spinner, Stack } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js'
import { toast } from 'react-toastify'
import { useGetCaiInstanceQuery, useGetMeQuery } from '../../gql/graphql'
import { isExpired, parseJWT } from '../../utils/auth'

export const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation()
  // utils
  const accessToken = localStorage.getItem('accessToken')
  const history = useHistory()

  // states
  const [auth, setAuth] = useState(accessToken ? { token: accessToken } : null)
  const [userInfo, setUserInfo] = useState()

  // apis
  const { data, loading } = useGetMeQuery({
    skip: !auth?.token,
    fetchPolicy: 'no-cache',
    onError: () => {
      history.push('/sign-in')
      toast.error(
        t(
          'auth.authProvider.toast.error',
          'Session has expired, please log back in.'
        )
      )
      console.error('Cannot get user info')
    }
  })

  const userId = data?.me?.id
  useGetCaiInstanceQuery({
    variables: { userId },
    skip: !userId,
    fetchPolicy: 'no-cache',
    onCompleted: (data) =>
      data && setUserInfo({ ...userInfo, caiInstance: data.caiInstance }),
    onError: () => {
      toast.error(
        t('common.toast.error', 'Something went wrong. Please try again later.')
      )
      console.error('Cannot get Cai instance')
    }
  })

  // funcs
  const login = (token) => {
    if (!token || isExpired(token)) return
    setAuth({ ...parseJWT(token), token })
    localStorage.setItem('accessToken', token)
    history.push('/')
  }

  const logout = () => {
    setAuth(null)
    setUserInfo(undefined)
    localStorage.removeItem('accessToken')
  }

  useEffect(() => {
    data && setUserInfo((state) => merge(state, data.me))
  }, [data])

  if (loading)
    return (
      <Stack style={{ height: '100vh' }}>
        <Spinner variant="primary" style={{ margin: 'auto' }} />
      </Stack>
    )

  return (
    <AuthContext.Provider
      value={{ auth, login, logout, userInfo, setUserInfo }}
    >
      {children}
    </AuthContext.Provider>
  )
}
