import Cookies from 'js-cookie'
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button, Stack } from 'react-bootstrap'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import SpeechRecognition from 'react-speech-recognition'
import { toast } from 'react-toastify'
import { AuthContext } from '../../components/Auth/AuthProvider'
import Tooltip from '../../components/UI/Tooltip'
import { CAI_CHAT_FLOWS } from '../../constants/cai-chat.constant'
import { getDaySentiment, submitSentiment } from '../../utils/platform'
import { CaiPopupContext } from '../CaiPopup/Context'
import Feedback from '../CaiPopup/Feedback'
import Librarian from '../CaiPopup/Librarian'
import Session from '../CaiPopup/Session'
import CAIIcon from '../UI/CAIIcon'
import CAIIconThinking from '../UI/CAIIconThinking'
import './Chat.style.scss'
import ChatBubble from './ChatBubble'
import ChatInput from './ChatInput'
import ReportButton from './ReportButton'
import TtsPlayer from './TtsPlayer'

function AgentChat(props) {
  const {
    messages,
    roleName,
    awaitingResponse,
    setAwaitingResponse,
    onInputTyping,
    moodButtonsEnabled,
    currentBubbles,
    onBubblesClick,
    onSendMessage,
    flow,
    setFlow,
    setMessagesMap,
    onReport,
    fullScreen,
    bubblesLoading,
    initialFeedback,
    initialFeedbackLoading,
    agentScope,
    tourOpen = false
  } = props

  const { t } = useTranslation()

  const { auth } = useContext(AuthContext)
  const { agentChatRef } = useContext(CaiPopupContext)

  // states
  const [txtMessage, setTxtMessage] = useState('')
  const [avatar, setAvatar] = useState('icofont-user-alt-3')
  const [showMoodButton, setShowMoodButton] = useState(false)
  const [isUserScrolling, setIsUserScrolling] = useState(false)

  // vars
  const bubblesContainerRef = useRef(null)

  // funcs
  const onChangeMessageText = (e) => {
    setTxtMessage(e)
    setShowMoodButton(false)
    onInputTyping()
    Cookies.set('showMoodButton', false, { expires: 1 })
  }

  // Trigger message prompting
  const onMessage = (msg, hidden = false) => {
    const m = (msg ?? txtMessage).trim()
    if (!m) return

    if (flow !== CAI_CHAT_FLOWS.LIBRARIAN) {
      setTxtMessage('')
    }

    setAwaitingResponse(true)
    SpeechRecognition.stopListening()
    onSendMessage(m, hidden).catch((e) => {
      console.error(e)
      toast.error('Error sending message.')
    })
  }

  const handleUserScroll = () => {
    const chatHistory = document.getElementById('chatHistory')
    // If the user is near the bottom (within 100px of the bottom), enable autoscroll
    if (
      chatHistory.scrollHeight -
        chatHistory.scrollTop -
        chatHistory.clientHeight <
      100
    ) {
      setIsUserScrolling(false)
    } else {
      setIsUserScrolling(true)
    }
  }

  const handleMoodButtonClick = (id) => {
    setShowMoodButton(false)
    const sentimentMapping = {
      grinning: 0.75,
      thinking: 0.5,
      pensive: 0.25
    }
    const sentiment = sentimentMapping[id]
    const day = new Date().toISOString().split('T')[0]
    submitSentiment(auth?.token, sentiment, day)
    Cookies.set('showMoodButton', false, { expires: 1 })
  }

  // Role change behaviour
  useEffect(() => {
    switch (roleName) {
      case 'presenter':
        setAvatar('icofont-presentation-alt')
        break
      case 'mentor':
        setTxtMessage('')
        setAvatar('icofont-teacher')
        break
      case 'cai':
        setTxtMessage('')
        setAvatar('cai')
    }
  }, [roleName])

  // Flow change behaviour
  useEffect(() => {
    if (flow !== CAI_CHAT_FLOWS.LIBRARIAN) setTxtMessage('')
  }, [flow])

  useEffect(() => {
    if (messages.length !== 1) return

    const fetchDaySentiment = async () => {
      const sentiment = await getDaySentiment(
        auth?.token,
        new Date().toISOString().split('T')[0]
      )
      if (sentiment === null) setShowMoodButton(true)
    }
    fetchDaySentiment()
  }, [messages])

  useEffect(() => {
    const chatHistory = document.getElementById('chatHistory')

    chatHistory?.addEventListener('scroll', handleUserScroll)

    // Cleanup event listener on component unmount
    return () => chatHistory?.removeEventListener('scroll', handleUserScroll)
  }, [])

  // Scroll to bottom of chat
  useLayoutEffect(() => {
    const chatHistory = document.getElementById('chatHistory')
    if (!isUserScrolling && chatHistory) {
      chatHistory.scrollTop = chatHistory.scrollHeight
    }
  }, [messages, isUserScrolling])

  if (flow === CAI_CHAT_FLOWS.LIBRARIAN) {
    return (
      <Librarian
        setFlow={setFlow}
        messages={messages}
        txtMessage={txtMessage}
        awaitingResponse={awaitingResponse}
        onChangeMessageText={onChangeMessageText}
        onMessage={onMessage}
        setTxtMessage={setTxtMessage}
        setMessagesMap={setMessagesMap}
        agentScope={agentScope}
      />
    )
  }

  if (flow === CAI_CHAT_FLOWS.FEEDBACK) {
    return (
      <Feedback
        flow={flow}
        setFlow={setFlow}
        txtMessage={txtMessage}
        awaitingResponse={awaitingResponse}
        onChangeMessageText={onChangeMessageText}
        onMessage={onMessage}
        setTxtMessage={setTxtMessage}
        initialFeedback={initialFeedback}
        initialFeedbackLoading={initialFeedbackLoading}
      />
    )
  }

  if (flow === CAI_CHAT_FLOWS.SESSION) {
    return (
      <Session
        setFlow={setFlow}
        txtMessage={txtMessage}
        awaitingResponse={awaitingResponse}
        onChangeMessageText={onChangeMessageText}
        onMessage={onMessage}
        setTxtMessage={setTxtMessage}
        messages={messages}
        setMessagesMap={setMessagesMap}
      />
    )
  }

  return (
    <div
      ref={agentChatRef}
      className="d-flex flex-column card card-chat-body border-0 order-1 w-100 position-relative h-100 p-3 gap-2"
    >
      <TtsPlayer tourOpen={tourOpen} messages={messages} />

      <div className="h-100 position-relative d-flex justify-content-end align-items-start overflow-hidden">
        <div
          className="h-100 w-100 position-absolute"
          style={{ transition: `top ease 0.3s` }}
        >
          <ul
            id="chatHistory"
            className="custchat-history list-unstyled mb-0 flex-grow-1 mh-100 overflow-auto"
          >
            {messages.map((data, i) => (
              <li
                key={i}
                className={
                  data.type === 'received'
                    ? 'mb-2 d-flex flex-row align-items-end'
                    : 'mb-4 d-flex flex-row-reverse align-items-end'
                }
              >
                <div
                  className={`${data.type === 'received' ? '' : 'text-end'}`}
                  style={{ maxWidth: isMobileOnly ? '85%' : '75%' }}
                >
                  <div className="d-flex gap-2 user-info mb-1 align-items-center">
                    {data.type === 'received' &&
                      (avatar === 'cai' ? (
                        <CAIIcon isCai style={{ width: 20, height: 20 }} />
                      ) : (
                        <i className={avatar}></i>
                      ))}

                    <span
                      className="text-capitalize"
                      style={{ fontSize: 12, color: 'var(--color-on-surface)' }}
                    >
                      {data.owner === 'user' ? 'you' : data.owner ?? 'Agent'}
                    </span>

                    <span className="text-muted small">{data.time}</span>
                  </div>

                  <ChatBubble data={data} />
                </div>
              </li>
            ))}
            <div
              className={
                fullScreen
                  ? `d-flex gap-3 align-items-center`
                  : 'd-flex flex-column gap-3'
              }
            >
              {!!messages.length && (
                <Stack
                  ref={bubblesContainerRef}
                  className="flex-row flex-wrap gap-1 mb-1"
                >
                  {currentBubbles.map((button) =>
                    button.tooltip ? (
                      <Tooltip
                        key={button.id}
                        message={button.tooltip}
                        container={bubblesContainerRef}
                      >
                        <Button
                          disabled={bubblesLoading}
                          className="btn-outline-primary p-1"
                          onClick={() => onBubblesClick(button)}
                          style={{ fontSize: 14 }}
                        >
                          {button.label}
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        key={button.id}
                        disabled={bubblesLoading}
                        className="btn-outline-primary p-1"
                        onClick={() => onBubblesClick(button)}
                        style={{ fontSize: 14 }}
                      >
                        {button.label}
                      </Button>
                    )
                  )}
                </Stack>
              )}
              {showMoodButton && moodButtonsEnabled && (
                <Stack
                  direction="horizontal"
                  gap={3}
                  className="user-info d-flex align-items-start"
                  style={{ width: 280, height: 40 }}
                >
                  <div className="d-flex w-100 align-items-center gap-3">
                    <span style={{ color: 'var(--text-color)' }}>
                      {t('agent.chat.showMoodTitle', 'How are you feeling?')}
                    </span>
                    <span className="d-flex flex-row gap-3 agent-chat-mood-btn-groups">
                      {[
                        { id: 'grinning', icon: '😀' },
                        { id: 'thinking', icon: '🤔' },
                        { id: 'pensive', icon: '😔' }
                      ].map((e) => (
                        <button
                          key={e.id}
                          className="btn fs-5 agent-chat-mood-btn border-0 p-0"
                          onClick={() => handleMoodButtonClick(e.id)}
                        >
                          {e.icon}
                        </button>
                      ))}
                    </span>
                  </div>
                </Stack>
              )}
            </div>
            {awaitingResponse && (
              <CAIIconThinking awaitingResponse={awaitingResponse} />
            )}
          </ul>
        </div>
      </div>

      <ChatInput
        txtMessage={txtMessage}
        awaitingResponse={awaitingResponse}
        onChangeMessageText={onChangeMessageText}
        onMessage={onMessage}
        setTxtMessage={setTxtMessage}
      />

      <ReportButton onReport={onReport} />
    </div>
  )
}

export default AgentChat
