import React, { useState } from 'react'
import {
  Tooltip as BootstrapTooltip,
  OverlayTrigger,
  OverlayTriggerProps
} from 'react-bootstrap'
import { OverlayTriggerRenderProps } from 'react-bootstrap/esm/OverlayTrigger'

type Props = {
  children:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | ((props: OverlayTriggerRenderProps) => React.ReactNode)
  message?: string
  props?: OverlayTriggerProps
  sticky?: boolean
}

function Tooltip({ children, message, props, sticky = false }: Props) {
  const [show, setShow] = useState(sticky)

  return (
    <OverlayTrigger
      show={show}
      onToggle={() => !sticky && setShow(!show)}
      overlay={(tooltipProps) => (
        <BootstrapTooltip {...tooltipProps}>
          <div className="d-flex flex-column align-items-center position-relative">
            {sticky && (
              <button
                type="button"
                aria-label="Close"
                onClick={() => setShow(false)}
                style={{
                  background: 'transparent',
                  border: 'none',
                  fontSize: '1.5rem',
                  cursor: 'pointer',
                  color: 'red',
                  position: 'absolute',
                  top: '0px',
                  right: '0px'
                }}
              >
                &times;
              </button>
            )}
            <span
              style={{ padding: '10px', textAlign: 'center', marginLeft: 10 }}
            >
              {' '}
              {/* Reduced padding */}
              {message}
            </span>
          </div>
        </BootstrapTooltip>
      )}
      {...props}
    >
      {children}
    </OverlayTrigger>
  )
}

export default Tooltip
