import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CaiPopupContext } from 'src/components/CaiPopup/Context'
import { CAI_CHAT_FLOWS } from '../../constants/cai-chat.constant'
import Tooltip from '../UI/Tooltip'

const IS_PROD = process.env.REACT_APP_ENV === 'prod' || false

function HeaderButtons({ newChat, flow }) {
  const { t } = useTranslation()
  const [isFeedbackClicked, setFeedbackClicked] = useState(false)
  const [isLibrarianClicked, setLibrarianClicked] = useState(false)
  const { newChatRef, librarianRef, feedFwdRef } = useContext(CaiPopupContext)

  useEffect(() => {
    switch (flow) {
      case CAI_CHAT_FLOWS.FEEDBACK: {
        setFeedbackClicked(true)
        setLibrarianClicked(false)
        break
      }
      case CAI_CHAT_FLOWS.LIBRARIAN: {
        setLibrarianClicked(true)
        setFeedbackClicked(false)
        break
      }
      default: {
        setFeedbackClicked(false)
        setLibrarianClicked(false)
      }
    }
  }, [flow])

  return (
    <>
      {!IS_PROD && (
        <Tooltip
          message={t(
            'caiPopup.headerButtons.tooltipFeedback.title',
            'Enter FeedForward mode to record and share feedback.'
          )}
        >
          <button
            className="btn btn-primary py-1 px-2 text-dark"
            style={{
              fontSize: 13,
              backgroundColor: 'rgb(255, 166, 166)',
              ...(isFeedbackClicked && {
                backgroundColor: 'rgb(235, 146, 146)',
                border: '2px solid #b30000'
              })
            }}
            ref={feedFwdRef}
            onClick={() => newChat(CAI_CHAT_FLOWS.FEEDBACK)}
          >
            {t('caiPopup.headerButtons.tooltipFeedback.button', 'FeedForward')}
          </button>
        </Tooltip>
      )}

      <Tooltip
        message={t(
          'caiPopup.headerButtons.tooltipConnect.title',
          'Connect with the Librarian that can explain any official policies and benefits.'
        )}
      >
        <button
          className="btn btn-primary py-1 px-2 text-dark"
          style={{
            fontSize: 13,
            backgroundColor: 'rgb(166, 226, 255)',
            ...(isLibrarianClicked && {
              backgroundColor: 'rgb(146, 206, 235)',
              border: '2px solid #0056b3'
            })
          }}
          ref={librarianRef}
          onClick={() => newChat(CAI_CHAT_FLOWS.LIBRARIAN)}
        >
          {t('caiPopup.headerButtons.tooltipConnect.button', 'Librarian')}
        </button>
      </Tooltip>

      <Tooltip
        message={t(
          'caiPopup.headerButtons.tooltipNewChat.title',
          'Start a new chat on a different topic.'
        )}
      >
        <button
          className="btn btn-primary py-1 px-2"
          style={{ fontSize: 13 }}
          ref={newChatRef}
          onClick={() => newChat(CAI_CHAT_FLOWS.DEFAULT)}
        >
          {t('caiPopup.headerButtons.tooltipNewChat.button', 'New Chat')}
        </button>
      </Tooltip>
    </>
  )
}

export default React.memo(HeaderButtons)
