import React, { createContext, useState } from 'react'

// Create context
export const CaiChatContext = createContext(null)

// Provider component
export const CaiChatProvider = ({ children }) => {
  const [message, setMessage] = useState('')

  const sendMessage = (newMessage) => {
    setMessage(newMessage)
  }

  const resetMessage = () => {
    setMessage('')
  }

  return (
    <CaiChatContext.Provider value={{ message, resetMessage, sendMessage }}>
      {children}
    </CaiChatContext.Provider>
  )
}
