import briefcase from '../../assets/icons/briefcase.svg'
import build from '../../assets/icons/build.svg'
import chartUp from '../../assets/icons/chart-up.svg'
import dashboard from '../../assets/icons/dashboard.svg'
import document from '../../assets/icons/document.svg'
import settings from '../../assets/icons/settings-alt.svg'
import task from '../../assets/icons/task.svg'
import tasksAndPrograms from '../../assets/icons/tasks-and-programs.svg'
import team from '../../assets/icons/team.svg'

const IS_DEV = process.env.REACT_APP_ENV === 'dev' || false

export const MENU = [
  {
    name: 'menu.hr',
    identifier: 'HR',
    children: [
      {
        name: 'menu.dashboard',
        routerLink: ['hr-dashboard'],
        identifier: 'hr-dashboard',
        iconClass: dashboard
      },
      ...(typeof IS_DEV !== 'undefined' && IS_DEV
        ? [
            {
              name: 'menu.admin',
              routerLink: ['hr-admin'],
              identifier: 'hr-admin',
              iconClass: settings
            }
          ]
        : []),
      {
        name: 'menu.tasksAndPrograms',
        routerLink: [],
        identifier: 'hr-tasks-and-programs',
        iconClass: tasksAndPrograms,
        children: [
          {
            name: 'menu.programs',
            routerLink: ['hr-programs'],
            identifier: 'hr-programs',
            iconClass: briefcase
          },
          {
            name: 'menu.programBuilder',
            routerLink: ['hr-program-builder'],
            identifier: 'hr-program-builder',
            iconClass: build
          },
          {
            name: 'menu.progressTracker',
            routerLink: ['hr-progress-tracker'],
            identifier: 'hr-progress-tracker',
            iconClass: chartUp
          },
          {
            name: 'menu.tasks',
            routerLink: ['hr-tasks'],
            identifier: 'hr-tasks',
            iconClass: task
          }
        ]
      },
      {
        name: 'menu.teams',
        routerLink: ['hr-teams'],
        identifier: 'hr-teams',
        iconClass: team
      },
      {
        name: 'menu.library',
        routerLink: ['hr-library'],
        identifier: 'hr-library',
        iconClass: document
      }
    ]
  },
  {
    name: 'menu.it',
    identifier: 'IT',
    children: [
      {
        name: 'menu.dashboard',
        routerLink: ['it-dashboard'],
        identifier: 'it-dashboard',
        iconClass: dashboard
      },
      {
        name: 'menu.programs',
        routerLink: ['it-programs'],
        identifier: 'it-programs',
        iconClass: briefcase
      },
      {
        name: 'menu.programBuilder',
        routerLink: ['it-program-builder'],
        identifier: 'it-program-builder',
        iconClass: build
      },
      {
        name: 'menu.progressTracker',
        routerLink: ['it-progress-tracker'],
        identifier: 'it-progress-tracker',
        iconClass: chartUp
      },
      {
        name: 'menu.tasks',
        routerLink: ['it-tasks'],
        identifier: 'it-tasks',
        iconClass: task
      },
      {
        name: 'menu.library',
        routerLink: ['it-library'],
        identifier: 'it-library',
        iconClass: document
      }
    ]
  },
  {
    name: 'menu.educator',
    identifier: 'EDUCATOR',
    children: [
      {
        name: 'menu.dashboard',
        routerLink: ['educator-dashboard'],
        identifier: 'educator-dashboard',
        iconClass: dashboard
      },
      {
        name: 'menu.programs',
        routerLink: ['educator-programs'],
        identifier: 'educator-programs',
        iconClass: briefcase
      },
      {
        name: 'menu.programBuilder',
        routerLink: ['educator-program-builder'],
        identifier: 'educator-program-builder',
        iconClass: build
      },
      {
        name: 'menu.progressTracker',
        routerLink: ['educator-progress-tracker'],
        identifier: 'educator-progress-tracker',
        iconClass: chartUp
      },
      {
        name: 'menu.tasks',
        routerLink: ['educator-tasks'],
        identifier: 'educator-tasks',
        iconClass: task
      },
      {
        name: 'menu.library',
        routerLink: ['educator-library'],
        identifier: 'educator-library',
        iconClass: document
      }
    ]
  },
  {
    name: 'menu.manager',
    identifier: 'MANAGER',
    children: [
      {
        name: 'menu.dashboard',
        routerLink: ['manager-dashboard'],
        identifier: 'manager-dashboard',
        iconClass: dashboard
      },
      {
        name: 'menu.programs',
        routerLink: ['manager-programs'],
        identifier: 'manager-programs',
        iconClass: briefcase
      },
      {
        name: 'menu.programBuilder',
        routerLink: ['manager-progress-tracker'],
        identifier: 'manager-progress-tracker',
        iconClass: chartUp
      },
      {
        name: 'menu.tasks',
        routerLink: ['manager-tasks'],
        identifier: 'manager-tasks',
        iconClass: task
      },
      {
        name: 'menu.library',
        routerLink: ['manager-library'],
        identifier: 'manager-library',
        iconClass: document
      },
      {
        name: 'menu.teams',
        routerLink: ['manager-teams'],
        identifier: 'manager-teams',
        iconClass: team
      }
    ]
  },
  {
    name: 'menu.employee',
    identifier: 'EMPLOYEE',
    children: [
      {
        name: 'menu.dashboard',
        routerLink: ['employee-dashboard'],
        identifier: 'employee-dashboard',
        iconClass: dashboard
      },
      {
        name: 'menu.programs',
        routerLink: ['employee-programs'],
        identifier: 'employee-programs',
        iconClass: briefcase
      },
      {
        name: 'menu.library',
        routerLink: ['employee-library'],
        identifier: 'employee-library',
        iconClass: document
      }
    ]
  }
]
