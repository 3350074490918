import { Icon } from '@iconify/react'
import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CaiPopupContext } from 'src/components/CaiPopup/Context'
import { Role } from 'src/gql/graphql'
import myCoachLogo from '../../assets/images/my-coach-logo.svg'
import { CaiChatProvider } from '../../context/CaiChatContext'
import { useClickOutside } from '../../utils/useClickOutside'
import { AuthContext } from '../Auth/AuthProvider'
import CaiPopup from '../CaiPopup'
import { MENU } from '../Data/menu'
import { Divider } from '../UI/Divider'
import LanguageSwitcher from './LanguageSwitcher'
import OrganizationSwitcher from './OrganizationSwitcher'

const IS_DEV = process.env.REACT_APP_ENV === 'dev' || false

function Sidebar(props) {
  const { activeKey } = props

  const { t } = useTranslation()

  const { hubMenuItem } = useContext(CaiPopupContext)
  const { userInfo } = useContext(AuthContext)
  const roleName = userInfo?.employeeDetails?.role?.name

  const [menuData, setMenuData] = useState([])
  const [darkLightMode, setDarkLightMode] = useState('light')
  const [updateRtl, setUpdateRtl] = useState(false)
  const [openingItem, setOpeningItem] = useState(
    localStorage.getItem('openingItem')
  )

  const ref = useClickOutside(() => {
    let side = document.getElementById('mainSideMenu')
    if (side && side.classList.contains('open')) {
      side.classList.remove('open')
    }
  })

  const handleItemClick = (item) => {
    localStorage.setItem('openingItem', item === openingItem ? undefined : item)
    setOpeningItem(item === openingItem ? undefined : item)
  }

  function onChangeDarkMode() {
    if (window.document.children[0].getAttribute('data-theme') === 'light') {
      window.document.children[0].setAttribute('data-theme', 'dark')
      setDarkLightMode('dark')
    } else {
      window.document.children[0].setAttribute('data-theme', 'light')
      setDarkLightMode('light')
    }
  }

  function onChangeRTLMode() {
    if (document.body.classList.contains('rtl_mode')) {
      document.body.classList.remove('rtl_mode')
    } else {
      document.body.classList.add('rtl_mode')
    }
    setUpdateRtl(!updateRtl)
  }

  useEffect(() => {
    if (roleName) {
      setMenuData(MENU.filter((e) => e.identifier === roleName))
    }
  }, [roleName])

  useEffect(() => {
    window.document.children[0].setAttribute('data-theme', 'light')
  }, [])

  return (
    <div
      ref={ref}
      id="mainSideMenu"
      className="sidebar px-3 py-4"
      style={{ maxHeight: '100vh' }}
    >
      <div className="d-flex flex-column h-100">
        <img
          src={userInfo?.organization?.logo || myCoachLogo}
          alt="my-coach-logo"
        />

        <div className="d-flex flex-column mt-2">
          <LanguageSwitcher />

          {IS_DEV && <OrganizationSwitcher />}
        </div>

        <Divider style={{ marginTop: 10 }} />

        <ul className="menu-list flex-grow-1 overflow-y-auto overflow-x-hidden pt-4 ">
          {menuData.map((d) => (
            <li key={d.name}>
              <ul className="ps-0 mt-2" style={{ listStyleType: 'none' }}>
                {d.children.map((data) => {
                  // render group
                  if (data.children) {
                    return (
                      <div key={data.name}>
                        <li
                          className="d-flex align-items-center"
                          style={{
                            paddingLeft: 12,
                            height: 52,
                            cursor: 'pointer',
                            ...(!!(activeKey === '/' + data.routerLink[0]) && {
                              backgroundColor: 'var(--color-primary-80)',
                              borderRadius: 8
                            })
                          }}
                          onClick={() => handleItemClick(data.name)}
                        >
                          <div className="d-flex gap-3">
                            <img
                              src={data.iconClass}
                              alt={data.name}
                              style={{ maxWidth: 22 }}
                            />

                            <div
                              className="fs-6 position-relative"
                              style={{ color: 'var(--color-on-primary)' }}
                            >
                              <div className="overflow-hidden">
                                {t(data.name)}
                              </div>

                              <Icon
                                className="position-absolute"
                                icon={`material-symbols-light:keyboard-arrow-${
                                  openingItem === data.name ? 'up' : 'down'
                                }`}
                                width="22"
                                height="22"
                                style={{ right: -25, top: 3 }}
                              />
                            </div>
                          </div>
                        </li>

                        {openingItem === data.name &&
                          data.children.map((child) => (
                            <Link
                              key={child.name}
                              to={`${process.env.PUBLIC_URL}/${child.routerLink[0]}`}
                            >
                              <li
                                className="d-flex align-items-center"
                                style={{
                                  paddingLeft: 30,
                                  height: 52,
                                  ...(!!(
                                    activeKey ===
                                    '/' + child.routerLink[0]
                                  ) && {
                                    backgroundColor: 'var(--color-primary-80)',
                                    borderRadius: 8
                                  })
                                }}
                              >
                                <div className="d-flex gap-3">
                                  <img src={child.iconClass} alt={child.name} />

                                  <span
                                    className="fs-6"
                                    style={{ color: 'var(--color-on-primary)' }}
                                  >
                                    {t(child.name)}
                                  </span>
                                </div>
                              </li>
                            </Link>
                          ))}
                      </div>
                    )
                  }

                  // render item
                  return (
                    <Link
                      key={data.name}
                      to={`${process.env.PUBLIC_URL}/${data.routerLink[0]}`}
                    >
                      <li
                        className="d-flex align-items-center"
                        style={{
                          paddingLeft: 12,
                          height: 52,
                          ...(!!(activeKey === '/' + data.routerLink[0]) && {
                            backgroundColor: 'var(--color-primary-80)',
                            borderRadius: 8
                          })
                        }}
                      >
                        <div className="d-flex gap-3">
                          <img src={data.iconClass} alt={data.name} />

                          <span
                            className="fs-6"
                            style={{ color: 'var(--color-on-primary)' }}
                          >
                            {t(data.name)}
                          </span>
                        </div>
                      </li>
                    </Link>
                  )
                })}
              </ul>
            </li>
          ))}

          {/* for employee */}
          {roleName === Role.Employee && (
            <li className="mt-3">
              <span style={{ color: 'var(--color-on-primary-40)' }}>
                Personal
              </span>

              <ul className="ps-0 mt-2" style={{ listStyleType: 'none' }}>
                <Link to="/personal-hub">
                  <li
                    ref={hubMenuItem}
                    className="d-flex align-items-center"
                    style={{
                      paddingLeft: 12,
                      height: 52,
                      ...(!!(activeKey === '/' + 'personal-hub') && {
                        backgroundColor: 'var(--color-primary-80)',
                        borderRadius: 8
                      })
                    }}
                  >
                    <div className="d-flex gap-3">
                      <Icon
                        icon="material-symbols-light:grid-view-outline-rounded"
                        width="24"
                        height="24"
                        style={{ color: 'white' }}
                      />

                      <span
                        className="fs-6"
                        style={{ color: 'var(--color-on-primary)' }}
                      >
                        Hub
                      </span>
                    </div>
                  </li>
                </Link>
              </ul>
            </li>
          )}
        </ul>

        <Divider style={{ marginBottom: 20 }} />

        <ul
          className="list-unstyled"
          style={{ opacity: 0.4, marginBottom: 30 }}
        >
          <li className="align-items-center justify-content-center">
            <div className="form-check form-switch px-3">
              <Form.Check
                type="checkbox"
                checked={darkLightMode === 'dark'}
                id="theme-switch"
                onChange={onChangeDarkMode}
                label={t('menu.darkMode').toUpperCase()}
              />
            </div>
          </li>

          <li className="align-items-center justify-content-center">
            <div className="form-check form-switch px-3">
              <Form.Check
                type="checkbox"
                checked={document.body.classList.contains('rtl_mode')}
                id="theme-rtl"
                onChange={onChangeRTLMode}
                label={t('menu.rtlMode').toUpperCase()}
              />
            </div>
          </li>
        </ul>

        <CaiChatProvider>
          <CaiPopup />
        </CaiChatProvider>

        <i
          className="mt-2"
          style={{ fontSize: 10, color: 'var(--color-on-primary-40)' }}
        >
          {`version: ${process.env.REACT_APP_VERSION}`}
        </i>
      </div>
    </div>
  )
}

export default Sidebar
