import { lazy } from 'react'
import { Route } from 'react-router-dom'

const SlackOAuthCallback = lazy(
  () => import('../components/Slack/SlackOAuthCallback')
)
const SlackOrganizationCallback = lazy(
  () => import('../components/Slack/SlackOrganizationCallback')
)

export default function OAuthIndex() {
  return (
    <div
      className="main p-2 py-3 p-xl-5 d-flex flex-column"
      style={{ position: 'relative' }}
    >
      <div className="body justify-content-center align-items-center d-flex p-0 p-xl-5">
        <div className="container-xxl">
          <div className="row g-0">
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/oauth/slack/callback`}
              component={SlackOAuthCallback}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/oauth/slack/organisation/callback`}
              component={SlackOrganizationCallback}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
