import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSpeechRecognition } from 'react-speech-recognition'
import sendIcon from '../../assets/icons/send.svg'
import Tooltip from '../UI/Tooltip'
import STTButton from '../shared/STTButton'

export default function ChatInput({
  txtMessage,
  awaitingResponse,
  onChangeMessageText,
  onMessage,
  setTxtMessage,
  customWrapperMessage,
  retainInput = false
}) {
  const speechApi = useSpeechRecognition()
  const { t } = useTranslation()

  const Wrapper = awaitingResponse ? Tooltip : Fragment
  const wrapperMessage =
    customWrapperMessage ||
    t('agent.chatInput.waitForResponse', 'Please wait for response')

  const handleSendMessage = () => {
    onMessage(txtMessage)
    if (!retainInput) setTxtMessage('')
    speechApi.resetTranscript()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSendMessage()
      speechApi.resetTranscript()
    }
  }

  return (
    <Wrapper {...(awaitingResponse && { message: wrapperMessage })}>
      <div
        className="custchat-message position-relative"
        style={{ backgroundColor: 'white' }}
      >
        <textarea
          className="form-control"
          rows={2}
          value={txtMessage}
          placeholder={
            awaitingResponse
              ? t('agent.chatInput.placeholder.disabled', 'Disabled...')
              : t('agent.chatInput.placeholder.enabled', 'Type a message...')
          }
          disabled={awaitingResponse}
          onChange={(e) => onChangeMessageText?.(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{
            paddingRight: 100,
            resize: 'none',
            backgroundColor: 'white'
          }}
        />

        <div
          className="d-flex position-absolute gap-2"
          style={{ right: 10, top: '50%', transform: 'translate(0,-50%)' }}
        >
          <STTButton
            awaitingResponse={awaitingResponse}
            setText={setTxtMessage}
            passiveColourCode="#000000"
            {...speechApi}
          />

          <button
            className="d-flex justify-content-center align-items-center btn btn-primary align-items-center"
            style={{ width: 40, height: 40 }}
            onClick={handleSendMessage}
            disabled={awaitingResponse}
          >
            <img src={sendIcon} alt={t('common.alt.send', 'Send')} />
          </button>
        </div>
      </div>
    </Wrapper>
  )
}
