import { useContext } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { isExpired } from '../../utils/auth'
import { AuthContext } from './AuthProvider'

const ProtectedRoute = withRouter(({ component: Component, ...rest }) => {
  const { auth, logout } = useContext(AuthContext)
  return (
    <Route
      {...rest}
      render={(props) => {
        const { pathname } = props.location
        if (pathname.includes('/oauth')) {
          return <Component {...props} {...rest} />
        }

        const isAuthenticatedAndNotExpired = auth && !isExpired(auth.token)

        if (isAuthenticatedAndNotExpired) {
          return <Component {...props} {...rest} />
        }

        if (auth && !isAuthenticatedAndNotExpired) {
          logout()
        }

        return (
          <Redirect
            to={{ pathname: '/sign-in', state: { from: props.location } }}
          />
        )
      }}
    />
  )
})

export default ProtectedRoute
