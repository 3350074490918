import { DefaultError, useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { AuthContext } from 'src/components/Auth/AuthProvider'
import QUERY_KEYS from 'src/constants/queryKey'
import { User } from 'src/gql/graphql'
import { Item } from 'src/types/session.type'

export default function useGetSessions() {
  const authContext: { userInfo: User } | null = useContext(AuthContext)

  const userId = authContext
    ? (authContext as { userInfo: User }).userInfo.stsUserId
    : null

  return useQuery<{ data: Item[] }, DefaultError, Item[]>({
    queryKey: [QUERY_KEYS.SESSIONS, userId],
    queryFn: ({ signal }) =>
      fetch(
        `${process.env.REACT_APP_CARCH_ENDPOINT}/sessions?user_id=${userId}`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        }
      )
        .then((r) => r.json())
        .catch((e) => {
          throw new Error(JSON.stringify(e))
        }),
    select: ({ data }) =>
      data.toSorted(
        (a, b) =>
          new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
      )
  })
}
