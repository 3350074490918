export default function CAIIcon({ style, isCai }) {
  return (
    <div
      className={`cognent_logo ${isCai && 'animate'}`}
      id="logo1"
      style={{ width: 40, height: 40, direction: 'ltr', ...style }}
    >
      <div className="logo__lines">
        <svg
          className="logo__line-a"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
        >
          <path fill="#ffffff" />
        </svg>
        <svg
          className="logo__line-b"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
        >
          <path fill="#ffffff" />
        </svg>
        <svg
          className="logo__line-c"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
        >
          <path fill="#ffffff" />
        </svg>
        <svg
          className="logo__line-d"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
        >
          <path fill="#ffffff" />
        </svg>
      </div>
      <svg
        className="logo__center"
        viewBox="0 0 196 196"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M82.0056 7.24957L7.24895 82.0062C-1.60631 90.8614 -1.60631 105.219 7.24895 114.074L82.0056 188.831C90.8608 197.686 105.218 197.686 114.073 188.831L188.83 114.074C197.685 105.219 197.685 90.8614 188.83 82.0062L114.073 7.24955C105.218 -1.60571 90.8608 -1.60569 82.0056 7.24957Z"
          fill="#ffffff"
        />
      </svg>
      <svg
        className="logo__gear"
        viewBox="0 0 634 634"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M623.395 211.67C623.395 211.67 532.699 182.163 545.975 87.7269C545.975 87.7269 470.648 29.5068 380.025 0C380.025 0 308.739 63.703 233.412 5.48293C233.412 5.48293 144.881 41.627 73.5943 105.33C73.5943 105.33 92.9312 198.54 4.40032 234.684C4.40032 234.684 -8.80354 329.12 10.5333 422.33C10.5333 422.33 101.229 451.837 87.9526 546.273C87.9526 546.273 163.28 604.493 253.903 634C253.903 634 325.261 570.297 400.516 628.517C400.516 628.517 489.047 592.373 560.406 528.67C560.406 528.67 541.069 435.46 629.6 399.316C629.6 399.316 642.804 304.88 623.467 211.742L623.395 211.67Z"
          fill="#2B3252"
          stroke="white"
          strokeWidth="6"
        />
      </svg>
    </div>
  )
}
