import parse from 'html-react-parser'
import { marked } from 'marked'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CAI_CHAT_FLOWS } from '../../constants/cai-chat.constant'
import ChatInput from '../Agent/ChatInput'
import TtsPlayer from '../Agent/TtsPlayer'
import { AuthContext } from '../Auth/AuthProvider'
import CAIIconThinking from '../UI/CAIIconThinking'
import Tooltip from '../UI/Tooltip'
import FileReference from './FileReference'

function Librarian(props) {
  const { t } = useTranslation()
  const {
    setFlow,
    messages,
    awaitingResponse,
    setMessagesMap,
    agentScope,
    ...rest
  } = props

  const { userInfo } = React.useContext(AuthContext)

  const handleNewQuery = () => {
    if (!messages.length) return
    setMessagesMap((state) => ({ ...state, librarian: [] }))
  }

  return (
    <div
      className="d-flex flex-column card card-chat-body border-0 order-1 w-100 position-relative h-100 p-3 gap-2"
      style={{ backgroundColor: '#e0f7fa' }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h1 style={{ fontSize: 'calc(1.5rem + 1vw)' }}>
          <i className="icofont-ebook"></i>
          {t('caiPopup.librarian.title', 'Librarian')}
        </h1>

        <div className="d-flex gap-2">
          <TtsPlayer messages={[]} />

          <Tooltip
            message={t(
              'caiPopup.librarian.tooltipLibrarian',
              'New librarian query.'
            )}
          >
            <button
              className="btn btn-outline-primary"
              onClick={handleNewQuery}
            >
              <i className="icofont-loop"></i>
            </button>
          </Tooltip>

          <Tooltip
            message={t(
              'caiPopup.librarian.tooltipReturnToChatMode',
              'Return to chat mode.'
            )}
          >
            <button
              className="btn btn-danger text-light"
              onClick={() => {
                // Set to the scope before entering Librarian flow
                agentScope.current = userInfo?.caiInstance?.currentScope
                setFlow(CAI_CHAT_FLOWS.DEFAULT)
              }}
            >
              <i className="icofont-close"></i>
            </button>
          </Tooltip>
        </div>
      </div>

      <div className="h-100 position-relative d-flex overflow-hidden">
        <div className="h-100 w-100 position-absolute d-flex flex-column">
          <div className="flex-grow-1 mh-100 overflow-auto gap-3 d-flex flex-column">
            <p>
              {t(
                'caiPopup.librarian.pleaseAskMeAnyQuestionsAbout',
                'Please ask me any questions about organisation policies and documents'
              )}{' '}
            </p>
            <div
              className="form-control flex-grow-1 h-50 overflow-y-auto rounded-2"
              style={{ backgroundColor: '#ffffff' }}
            >
              {messages.length ? (
                messages[messages.length - 1].owner !== 'user' && (
                  <>
                    {parse(marked.parse(messages[messages.length - 1].message))}
                    {[
                      ...new Map(
                        messages[messages.length - 1].references?.map(
                          (reference) => [reference.name, reference]
                        )
                      ).values()
                    ].map((reference) => (
                      <FileReference
                        key={reference.uri + reference.name}
                        text={reference.name}
                        link={reference.uri}
                      />
                    ))}
                  </>
                )
              ) : (
                <div>
                  {t('caiPopup.librarian.awaitingQuery', 'Awaiting query...')}
                </div>
              )}

              {awaitingResponse && (
                <CAIIconThinking awaitingResponse={awaitingResponse} />
              )}
            </div>
          </div>
        </div>
      </div>

      <ChatInput
        {...rest}
        awaitingResponse={awaitingResponse}
        retainInput={true}
      />
    </div>
  )
}

export default React.memo(Librarian)
