import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SpeechRecognition from 'react-speech-recognition'

export default function STTButton(props) {
  const {
    setText,
    passiveColourCode,
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    resetTranscript,
    awaitingResponse
  } = props
  const { i18n } = useTranslation()

  const manuallyStopped = useRef(false) // Track if stopped manually

  const startListening = () => {
    manuallyStopped.current = false
    var ttsLanguage
    if (i18n.language === 'fr') {
      ttsLanguage = 'fr-FR'
    } else {
      ttsLanguage = 'en-US'
    }

    SpeechRecognition.startListening({
      continuous: true,
      language: ttsLanguage
    })
  }

  const stopListening = () => {
    manuallyStopped.current = true
    SpeechRecognition.stopListening()
    resetTranscript()
  }

  const onClick = () => {
    if (listening) {
      stopListening()
    } else {
      startListening()
    }
  }

  useEffect(() => {
    const handleEnd = () => {
      if (!manuallyStopped.current) {
        startListening() // Restart if not manually stopped
      }
    }

    SpeechRecognition.onend = handleEnd // Restart on end event

    return () => {
      SpeechRecognition.onend = null // Clean up listener
    }
  }, [])

  useEffect(() => {
    if (transcript) {
      setText?.(transcript)
    }
  }, [transcript])

  if (!browserSupportsSpeechRecognition) {
    console.warn("Browser doesn't support speech recognition.")
    return null
  }

  return (
    <button
      className="btn btn-primary d-flex align-items-center justify-content-center rounded-circle border-0"
      disabled={awaitingResponse}
      style={{
        width: 40,
        height: 40,
        background: listening ? 'red' : 'none'
      }}
      onClick={onClick}
    >
      <i
        className="icofont-mic"
        style={{
          color: listening ? '#fff' : passiveColourCode,
          fontSize: 25
        }}
      ></i>
    </button>
  )
}
