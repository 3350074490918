import { useState } from 'react'
import { Modal } from 'react-bootstrap'

export default function ImageViewer(props) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)

  const handleShow = () => setShow(true)

  return (
    <>
      <img onClick={handleShow} {...props} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <img {...props} className="w-100" />
        </Modal.Body>
      </Modal>
    </>
  )
}
