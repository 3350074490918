import { DefaultError, useQuery } from '@tanstack/react-query'
import { useContext, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import TtsPlayer from 'src/components/Agent/TtsPlayer'
import { CAI_CHAT_FLOWS } from 'src/constants/cai-chat.constant'
import QUERY_KEYS from 'src/constants/queryKey'
import ChatBubble from '../../Agent/ChatBubble'
import ChatInput from '../../Agent/ChatInput'
import { NotificationContext } from '../../Notification/NotificationContext'
import { CAIIconAnim } from '../../shared/CAIIconAnim'
import CAIIcon from '../../UI/CAIIcon'
import Tooltip from '../../UI/Tooltip'
import SubmitButton from './SubmitButton'

type Message = {
  message: string
  type: string
  images: string[]
  time: string
  owner: string
  avatar: any
}

type Data = {
  chat_id: string
  hidden: boolean
  id: string
  sender: string
  text: string
  timestamp: string
}

function Session(props: any) {
  const {
    setFlow,
    messages,
    awaitingResponse,
    setMessagesMap,
    agentScope,
    ...rest
  } = props

  const { t, i18n } = useTranslation()

  const {
    sessionId,
    setSessionId,
    handleCompleteActivity,
    isSubmitted,
    setIsSubmitted
  } = useContext(NotificationContext)

  const { data = [], isLoading } = useQuery<
    { data: Data[] },
    DefaultError,
    Message[]
  >({
    queryKey: [QUERY_KEYS.SESSION, sessionId],
    queryFn: ({ signal }) =>
      fetch(
        `${process.env.REACT_APP_CARCH_ENDPOINT}/sessions/${sessionId}/messages?language=${i18n.language}`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        }
      )
        .then((r) => r.json())
        .catch((e) => {
          throw new Error(JSON.stringify(e))
        }),
    select: ({ data }) =>
      data
        .filter((i) => !i.hidden)
        .map((i) => ({
          message: i.text,
          type: i.sender === 'user' ? 'sent' : 'received',
          images: [],
          time: new Date(i.timestamp).toLocaleTimeString(),
          owner: i.sender,
          avatar: CAIIcon
        }))
  })

  const handleClose = () => {
    setFlow(CAI_CHAT_FLOWS.DEFAULT)
    setMessagesMap((state: any) => ({ ...state, session: [] }))
    setSessionId('')
  }

  const handleOnMessage = async (message: string, hidden: boolean) => {
    try {
      await rest.onMessage(message, hidden)
    } finally {
      setIsSubmitted(true)
    }
  }

  useEffect(() => {
    setMessagesMap((state: any) => ({ ...state, session: data }))
  }, [JSON.stringify(data)])

  if (isLoading) return <CAIIconAnim />

  return (
    <div className="d-flex flex-column card card-chat-body border-0 order-1 w-100 position-relative h-100 p-3 gap-2">
      <div className="h-100 position-relative d-flex overflow-hidden">
        <div className="h-100 w-100 position-absolute d-flex flex-column">
          {/* chat box*/}
          <div className="flex-grow-1 mh-100 overflow-auto gap-3 d-flex flex-column">
            {messages.map((data: any, i: number) => (
              <li
                key={i}
                className={
                  data.type === 'received'
                    ? 'mb-2 d-flex flex-row align-items-end'
                    : 'mb-4 d-flex flex-row-reverse align-items-end'
                }
              >
                <div
                  className={`${data.type === 'received' ? '' : 'text-end'}`}
                  style={{ maxWidth: isMobileOnly ? '85%' : '75%' }}
                >
                  <div className="d-flex gap-2 user-info mb-1 align-items-center">
                    <span
                      className="text-capitalize"
                      style={{ fontSize: 12, color: 'var(--color-on-surface)' }}
                    >
                      {data.owner === 'user'
                        ? t('caiPopup.session.you', 'you')
                        : data.owner ?? t('caiPopup.session.agent', 'Agent')}
                    </span>

                    <span className="text-muted small">{data.time}</span>
                  </div>

                  <ChatBubble data={data} />
                </div>
              </li>
            ))}
          </div>
        </div>

        <div className="d-flex gap-2 position-absolute top-0 end-0 align-items-center">
          <TtsPlayer messages={messages} />

          {/* close button */}
          {!handleCompleteActivity && (
            <Tooltip message="Return to chat mode.">
              <button
                className="btn btn-danger p-1 text-light d-flex"
                onClick={handleClose}
                style={{ height: 24 }}
              >
                <i className="icofont-close" style={{ marginTop: 1 }}></i>
              </button>
            </Tooltip>
          )}
        </div>

        {!isSubmitted && (
          <SubmitButton
            onMessage={handleOnMessage}
            disabled={messages.length <= 1}
          />
        )}
      </div>

      <ChatInput {...rest} awaitingResponse={awaitingResponse} />
    </div>
  )
}

export default Session
