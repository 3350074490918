import { useTranslation } from 'react-i18next'
import myCoachLogo from '../../assets/images/my-coach-logo-2.svg'

function LeftSide() {
  const { t } = useTranslation()
  return (
    <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
      <div style={{ maxWidth: '25rem' }}>
        <div className="">
          <img
            src={myCoachLogo}
            alt="my-coach-logo"
            className="bi bi-clipboard-check"
            style={{
              width: '400px'
            }}
          />
        </div>
        <div
          style={{
            width: '400px',
            height: '245px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Georgia, serif',
            fontSize: '40px',
            fontStyle: 'italic',
            color: 'var(--color-primary)'
          }}
        >
          {t('auth.leftSide.title', 'Grow & Flourish')}
        </div>
      </div>
    </div>
  )
}

export default LeftSide
