import { memo, useContext, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CAI_CHAT_FLOWS } from 'src/constants/cai-chat.constant'
import { CaiChatContext } from '../../context/CaiChatContext'
import CaiChat from '../../screens/App/CaiChat'
import { AuthContext } from '../Auth/AuthProvider'
import { NotificationContext } from '../Notification/NotificationContext'
import CAIIcon from '../UI/CAIIcon'
import Tooltip from '../UI/Tooltip'
import STTButton from '../shared/STTButton'
import { CaiPopupContext } from './Context'

const CaiPopup = ({ showInput = true }) => {
  const { t } = useTranslation()
  const { userInfo } = useContext(AuthContext)
  const { sendMessage } = useContext(CaiChatContext)
  const { show, setShow, setFlow, aiChatIconRef } = useContext(CaiPopupContext)
  const { setSessionId } = useContext(NotificationContext)

  const [caiSmile, setCaiSmile] = useState(false)
  const [fullSize, setFullSize] = useState(true)
  const [txtMessage, setTxtMessage] = useState('')

  const caiChatOpenCountRef = useRef(0)
  const inputRef = useRef(null)

  const handleShow = (e) => {
    setShow((state) => !state)
    caiChatOpenCountRef.current += 1
  }

  const handleCai = () => setCaiSmile((state) => !state)

  const handleFullSize = () => setFullSize((state) => !state)

  const handleSendMessage = (event) => {
    if (event.key === 'Enter' && event.target.value.trim()) {
      sendMessage(event.target.value)
      setTxtMessage('')
      !show && handleShow()
    }
  }

  useEffect(() => {
    if (userInfo?.isFirstLogin) {
      handleShow()
      setCaiSmile(true)
    }
  }, [userInfo?.isFirstLogin])

  useEffect(() => {
    if (caiSmile && inputRef.current !== null) {
      inputRef.current.focus()
    }
  }, [caiSmile])

  useEffect(() => {
    if (!show) {
      setFlow(CAI_CHAT_FLOWS.DEFAULT)
      setSessionId('')
    }
  }, [show])

  return (
    <>
      <div className="d-flex justify-content-center">
        <Tooltip message={t('caiPopup.tooltip')}>
          <button
            ref={aiChatIconRef}
            onClick={handleShow}
            className="p-2 btn mb-3"
            style={{
              backgroundColor: '#fff',
              borderRadius: '100px 100px 100px 0',
              boxShadow: '0 0 40px rgba(242, 229, 193, 1)'
            }}
          >
            <CAIIcon isCai={show} style={{ width: 50, height: 50 }} />
          </button>
        </Tooltip>
      </div>

      {showInput && (
        <span className="d-flex align-items-center">
          <Form.Control
            value={txtMessage}
            onChange={(e) => setTxtMessage(e.target.value)}
            placeholder={t('caiPopup.placeholder')}
            onKeyUp={handleSendMessage}
            className="border-0 text-light"
            style={{
              height: 40,
              backgroundColor: 'var(--color-on-primary-20)'
            }}
          />

          <span className="position-relative" style={{ right: -8 }}>
            <STTButton
              {...(!show && { setText: setTxtMessage })}
              passiveColourCode="#F9F6EE"
            />
          </span>
        </span>
      )}

      {show && (
        <CaiChat
          handleShow={() => {
            handleShow()
            handleCai()
          }}
          handleFullSize={handleFullSize}
          fullScreen={fullSize}
        />
      )}
    </>
  )
}

export default memo(CaiPopup)
